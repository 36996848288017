import { useCallback } from 'react';
import classnames from 'classnames';

import vacancyResponseShowResumeButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/response/vacancy_response_show_resume_button_click';
import { Cell, CellText, Link as MagritteLink, Radio as MagritteRadio, useBreakpoint } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import { FormHint } from 'bloko/blocks/form';
import Gap from 'bloko/blocks/gap';
import BlokoLink from 'bloko/blocks/link';
import Radio from 'bloko/blocks/radio';
import Text, { TextSize } from 'bloko/blocks/text';
import { type TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Debug from 'HHC/Debug';
import translation from 'src/components/translation';
import useMagritteResponseExp from 'src/hooks/useMagritteResponseExp';
import { type ResponseStatusResume } from 'src/models/applicantVacancyResponseStatuses';
import { type ResumeVisibility } from 'src/models/vacancyResponsePopup';
import { type VacancySearchItem } from 'src/types/search/vacancy/card';

import ResumeAvatar from 'src/components/VacancyResponseForm/ResumeAvatar';
import ResumeDetail from 'src/components/VacancyResponseForm/ResumeDetail';
import ResumeHint from 'src/components/VacancyResponseForm/ResumeHint';
import VisibilityHint from 'src/components/VacancyResponseForm/VisibilityHint';

interface ResumeProps {
    resume: ResponseStatusResume;
    selected: boolean;
    single: boolean;
    hidden: boolean;
    onSelect: (resume: ResponseStatusResume) => void;
    visibility: ResumeVisibility;
    vacancy: VacancySearchItem;
    isQuestion?: boolean;
    isBottomSheet?: boolean;
}

const TrlKeys = {
    resumeLink: {
        view: 'vacancy.response.popup.resume.link',
        toSettings: 'vacancy.response.popup.resume.settings',
    },
    incompleteHint: 'vacancy.response.popup.unfinished.hint',
    emptyTitle: 'resume.title.empty',
};

const Resume: TranslatedComponent<ResumeProps> = ({
    resume,
    selected,
    single,
    hidden,
    onSelect,
    visibility,
    vacancy,
    trls,
    isQuestion,
    isBottomSheet,
}) => {
    if (!resume.title?.[0]?.string) {
        Debug.log('out error', new Error('Empty resume title'), {
            resume: {
                title: resume.title,
                titleFirst: resume.title?.[0],
                titleString: resume.title?.[0]?.string,
                accessType: resume.accessType,
                isIncomplete: resume.isIncomplete,
                ...resume._attributes,
            },
        });
    }
    const { hash, isIncomplete } = resume;
    // тайтла может не быть, если это незавершенное дублированное резюме
    const name = resume.title?.[0]?.string ?? trls[TrlKeys.emptyTitle];

    const { isMagritteResponseExpAny } = useMagritteResponseExp();
    const { isMobile } = useBreakpoint();

    const handleViewResumeClick = useCallback(() => {
        vacancyResponseShowResumeButtonClick({ vacancyId: `${vacancy?.vacancyId}` });
    }, [vacancy?.vacancyId]);

    if (isMagritteResponseExpAny) {
        const link = hidden ? (
            <MagritteLink
                Element={Link}
                to={`/applicant/resumes/edit/visibility?resume=${hash}`}
                target="_blank"
                typography={!isMobile ? 'label-2-regular' : 'label-3-regular'}
            >
                {trls[TrlKeys.resumeLink.toSettings]}
            </MagritteLink>
        ) : (
            <MagritteLink
                Element={Link}
                to={`/resume/${hash}`}
                target="_blank"
                data-qa="vacancy-response-view-resume"
                typography={!isMobile ? 'label-2-regular' : 'label-3-regular'}
                onClick={handleViewResumeClick}
            >
                {trls[TrlKeys.resumeLink.view]}
            </MagritteLink>
        );
        const renderRadio = () => (
            <MagritteRadio
                id={`resume_${hash}`}
                name="resumeId"
                value={resume.hash}
                checked={selected}
                disabled={hidden}
                onChange={() => {
                    onSelect(resume);
                }}
            />
        );

        const hasRightRadio = !single && isBottomSheet;
        const hasLeftRadio = !single && !isBottomSheet;
        const leftContent = hasLeftRadio ? renderRadio() : undefined;

        const renderRightContent = () => {
            if (hasRightRadio) {
                return renderRadio();
            }
            return !isMobile ? link : undefined;
        };

        return (
            <>
                <Cell left={leftContent} avatar={<ResumeAvatar resume={resume} />} right={renderRightContent()}>
                    <CellText type="title" maxLines={1} data-qa="resume-title">
                        {name}
                    </CellText>
                    <CellText type="subtitle" maxLines={1}>
                        <ResumeDetail resume={resume} />
                    </CellText>
                </Cell>
                {!isMobile && (
                    <ResumeHint
                        resume={resume}
                        visibility={visibility}
                        vacancy={vacancy}
                        hidden={hidden}
                        incomplete={isIncomplete}
                        visible={selected}
                        isQuestion={isQuestion}
                    />
                )}
            </>
        );
    }

    const input = single ? (
        <span data-qa="resume-title">{name}</span>
    ) : (
        <Radio
            checked={selected}
            onChange={() => onSelect(resume)}
            id={`resume_${hash}`}
            labelProps={{ 'data-qa': 'resume-title' }}
            disabled={hidden}
        >
            {name}
        </Radio>
    );

    const link = hidden ? (
        <BlokoLink
            disableVisited
            Element={Link}
            to={`/applicant/resumes/edit/visibility?resume=${hash}`}
            target="_blank"
        >
            {trls[TrlKeys.resumeLink.toSettings]}
        </BlokoLink>
    ) : (
        <BlokoLink
            disableVisited
            Element={Link}
            to={`/resume/${hash}`}
            target="_blank"
            className="vacancy-response-popup-resume__link"
            data-qa="vacancy-response-view-resume"
            onClick={handleViewResumeClick}
        >
            <Text Element="span" size={TextSize.Small}>
                {trls[TrlKeys.resumeLink.view]}
            </Text>
        </BlokoLink>
    );

    const incompleteHint = !!isIncomplete && !isQuestion && (
        <div className="vacancy-response-popup-resume__hint">
            <FormHint>{trls[TrlKeys.incompleteHint]}</FormHint>
        </div>
    );

    return (
        <div
            className={classnames('vacancy-response-popup-resume', {
                'vacancy-response-popup-resume_selected': selected,
            })}
        >
            <div className="vacancy-response-popup-resume__title">
                <Gap right>{input}</Gap>
                {link}
            </div>
            <VisibilityHint resume={resume} visibility={visibility} vacancy={vacancy} hidden={hidden} />
            {incompleteHint}
        </div>
    );
};

export default translation(Resume);
